table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

tr {
  height: 2rem;
  padding: 0;
  margin: 0;
}
th,
td {
  border: .5px solid #6669;
  height: 2rem;
  padding-left: .25rem;
  padding-right: .25rem;
  cursor: text;
}

.table-input {
    height: 100%;
    width: 100%;
    border: none;  
    margin: 0;
    padding: 0; 
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
}